import { RouteRecordRaw, RouterView, useRoute } from 'vue-router';
import { h } from 'vue';
import store from '@/store';
import router from '@/router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/calculator/:garmentIdentifier',
            name: 'layout',
            component: () => import("./views/estimation/layout.vue"),
            children: [
                {
                    path: '/calculator/:garmentIdentifier',
                    name: 'start',
                    component: () => import("./views/estimation/start.vue"),
                },
                {
                    path: '',
                    name: 'editestimationlayout',
                    component: () => import("./views/estimation/editEstimationLayout.vue"),
                    children: [
                        {
                            path: '/calculator/editbaseinformation/:identifier',
                            name: 'editbaseinformation',
                            component: () => import("./views/estimation/editEstimation/editBaseInformation.vue"),
                        },
                        {
                            path: '/calculator/rawmaterials/:identifier',
                            name: 'editrawmaterials',
                            component: () => import("./views/estimation/editEstimation/editRawMaterials.vue"),
                        },
                        {
                            path: '/calculator/productionprocess/:identifier/:process',
                            name: 'editproductionprocess',
                            component: () => import("./views/estimation/editEstimation/editProcess.vue"),
                        },
                        {
                            path: '/calculator/:identifier/finaltransportation',
                            name: 'finaltransportation',
                            component: () => import("./views/estimation/editEstimation/editFinalTransportation.vue"),
                        },
                        {
                            path: '/calculator/:identifier/summary',
                            name: 'summary',
                            component: () => import("./views/estimation/editEstimation/summary.vue"),
                        },
                    ]
                }
            ]
        },
        {
            path: '/footwear-calculator/:garmentIdentifier',
            name: 'footwear layout',
            component: () => import("./views/footwear/footwearLayout.vue"),
            children: [
                {
                    path: '/footwear-calculator/:garmentIdentifier',
                    name: 'footwear start',
                    component: () => import("./views/footwear/start.vue"),
                },
                {
                    path: '/footwear-calculator/components-weights/:estimationIdentifier',
                    name: 'componentsWeights',
                    component: () => import("./views/footwear/componentsWeights.vue"),
                },
                {
                    path: '/footwear-calculator/components-materials/:estimationIdentifier',
                    name: 'componentsMaterial',
                    component: () => import("./views/footwear/componentsMaterial.vue"),
                },
                {
                    path: '/footwear-calculator/chemicals/:estimationIdentifier',
                    name: 'chemicals',
                    component: () => import("./views/footwear/chemicals.vue"),
                },
                {
                    path: '/footwear-calculator/accessories/:estimationIdentifier',
                    name: 'accessories',
                    component: () => import("./views/footwear/accessories.vue"),
                },
                {
                    path: '/footwear-calculator/processes-locations-and-transports/:estimationIdentifier',
                    name: 'processes',
                    component: () => import("./views/footwear/processesLocationsAndTransports.vue"),
                },
                {
                    path: '/footwear-calculator/packaging/:estimationIdentifier',
                    name: 'packaging',
                    component: () => import("./views/footwear/packaging.vue"),
                },
                {
                    path: '/footwear-calculator/summary/:estimationIdentifier',
                    name: 'footwear summary',
                    component: () => import("./views/footwear/summary.vue"),
                },
            ]
        },
    ];
};